import './App.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
// import Home from "./pages/home";
import React, {lazy, Suspense} from "react";
import ErrorPage from './ErrorPage';

const Home = lazy(() => import("./pages/home"));
const BusinessSolution = lazy(() => import("./pages/businessSolution"));
const ShipmentCalculator = lazy(() => import("./pages/shipmentCalculator"));
const TermAndConditions = lazy(() => import("./pages/termAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy"));
const ApiDocumentation = lazy(() => import("./pages/apiDocumentation"));
const Walmart = lazy(() => import("./pages/walmart"));

const router = createBrowserRouter([
    {
        path: "/",
        element: <Suspense fallback={ <div id='loader' className={"items-center justify-center"} style={{display:"flex",alignItems:"center",margin:"auto", position: "fixed",width:"100%",height:"100vh",backgroundColor:"white",zIndex:"99999",top:"0",left:"0",overflow:"hidden"}}>
            <img style={{width:"60px"}} alt={"loading"} src={"https://www.aquecimentoindustrial.com.br/wp-content/plugins/interactive-3d-flipbook-powered-physics-engine/assets/images/dark-loader.gif"}/>
        </div>}><Home /></Suspense>,
    },
    {
        path: "*",
        element: <ErrorPage/>,
    },
    // {
    //     path: "/business-solution",
    //     element: <Suspense fallback={ <div id='loader' className={"items-center justify-center"} style={{display:"flex",alignItems:"center",margin:"auto", position: "fixed",width:"100%",height:"100vh",backgroundColor:"white",zIndex:"99999",top:"0",left:"0",overflow:"hidden"}}>
    //         <img style={{width:"60px"}} alt={"loading"} src={"https://www.aquecimentoindustrial.com.br/wp-content/plugins/interactive-3d-flipbook-powered-physics-engine/assets/images/dark-loader.gif"}/>
    //     </div>}><BusinessSolution /></Suspense>,
    // },
    // {
    //     path: "/shipping-calculator",
    //     element: <Suspense fallback={ <div id='loader' className={"items-center justify-center"} style={{display:"flex",alignItems:"center",margin:"auto", position: "fixed",width:"100%",height:"100vh",backgroundColor:"white",zIndex:"99999",top:"0",left:"0",overflow:"hidden"}}>
    //         <img style={{width:"60px"}} alt={"loading"} src={"https://www.aquecimentoindustrial.com.br/wp-content/plugins/interactive-3d-flipbook-powered-physics-engine/assets/images/dark-loader.gif"}/>
    //     </div>}><ShipmentCalculator /></Suspense>,
    // },
    // {
    //     path: "/terms-and-conditions",
    //     element: <Suspense fallback={ <div id='loader' className={"items-center justify-center"} style={{display:"flex",alignItems:"center",margin:"auto", position: "fixed",width:"100%",height:"100vh",backgroundColor:"white",zIndex:"99999",top:"0",left:"0",overflow:"hidden"}}>
    //         <img style={{width:"60px"}} alt={"loading"} src={"https://www.aquecimentoindustrial.com.br/wp-content/plugins/interactive-3d-flipbook-powered-physics-engine/assets/images/dark-loader.gif"}/>
    //     </div>}><TermAndConditions /></Suspense>,
    // },
    // {
    //     path: "/privacy-policy",
    //     element: <Suspense fallback={ <div id='loader' className={"items-center justify-center"} style={{display:"flex",alignItems:"center",margin:"auto", position: "fixed",width:"100%",height:"100vh",backgroundColor:"white",zIndex:"99999",top:"0",left:"0",overflow:"hidden"}}>
    //         <img style={{width:"60px"}} alt={"loading"} src={"https://www.aquecimentoindustrial.com.br/wp-content/plugins/interactive-3d-flipbook-powered-physics-engine/assets/images/dark-loader.gif"}/>
    //     </div>}><PrivacyPolicy /></Suspense>,
    // },
    // {
    //     path: "/api-documentation",
    //     element: <Suspense fallback={ <div id='loader' className={"items-center justify-center"} style={{display:"flex",alignItems:"center",margin:"auto", position: "fixed",width:"100%",height:"100vh",backgroundColor:"white",zIndex:"99999",top:"0",left:"0",overflow:"hidden"}}>
    //         <img style={{width:"60px"}} alt={"loading"} src={"https://www.aquecimentoindustrial.com.br/wp-content/plugins/interactive-3d-flipbook-powered-physics-engine/assets/images/dark-loader.gif"}/>
    //     </div>}><ApiDocumentation /></Suspense>,
    // },
    // {
    //     path: "/partner/walmart",
    //     element: <Suspense fallback={ <div id='loader' className={"items-center justify-center"} style={{display:"flex",alignItems:"center",margin:"auto", position: "fixed",width:"100%",height:"100vh",backgroundColor:"white",zIndex:"99999",top:"0",left:"0",overflow:"hidden"}}>
    //         <img style={{width:"60px"}} alt={"loading"} src={"https://www.aquecimentoindustrial.com.br/wp-content/plugins/interactive-3d-flipbook-powered-physics-engine/assets/images/dark-loader.gif"}/>
    //     </div>}><Walmart /></Suspense>,
    // },
]);


function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
