import { react, useRouteError } from "react-router-dom";
import {Link} from "react-router-dom";

// Images
import Logo from './assets/images/shipcheap.svg'
import LiningBg from './assets/images/lining-bg.svg'

export default function ErrorPage() {
  const error = useRouteError();
  return (
      <div className="relative overflow-hidden h-[100vh] md:flex flex-col w-full bg-[#070A1F] justify-center hidden text-center">
         <div className="absolute -top-36 -left-36 w-4/5 h-4/5 bg-[#2F3885] rounded-full blur-[450px] z-0"></div>
         <div className="px-20 my-5 w-full z-[1]">
            {/* <img src={Logo} alt="Null Ship Logo" className="mt-20 mb-6" width={100} /> */}
            <h1 className="text-white font-semibold text-[120px]">404</h1>
            <p className="text-white text-2xl">Sorry, the page you are looking for could not be found.</p>
            <Link to="/" className='btn-primary bg-secondary mt-7 bg-gradient'>Back to Home</Link>
         </div>
         <div className="absolute bottom-0 right-0">
            <img src={LiningBg} alt="Lining bg animation" />
         </div>
      </div>
  );
}
